import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n,
  r as r2,
  r2 as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-multi-checkbox/jb-multi-checkbox.scss?inline
var jb_multi_checkbox_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p.error, p:not(.error) {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

p:not(.error) {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}

p.error {
  font-size: var(--theme-typo-p-mini-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-desktop-lineheight, 1.25rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

p:not(.error) {
  margin-block-end: var(--spacing-xs);
}

jb-checkbox:not(:last-child) {
  margin-block-end: var(--spacing-xs);
}

p.error {
  color: #971b2f;
  display: block;
  margin-block-start: var(--spacing-xs);
}
p.error [data-error-code] {
  display: none;
}
p.error [data-force-initial-display] {
  display: block;
}`;

// src/components/jb-multi-checkbox/jb-multi-checkbox.ts
var JbMultiCheckbox = class extends Initiable(h) {
  constructor() {
    super(...arguments);
    this.minimumChecks = 0;
    this.showError = false;
  }
  appendFormData(formDataEvent) {
    const values = [...this._checkboxes].filter((el) => el.isChecked()).map((el) => el.value);
    values.forEach((value) => formDataEvent.formData.append(this.name + "[]", value));
  }
  validate() {
    const checkedAmount = [...this._checkboxes].filter((el) => el.isChecked()).length;
    const valid = checkedAmount >= this.minimumChecks;
    this.showError = !valid;
    return valid;
  }
  render() {
    return ke`
      <div>
        <p>${this.label}${this.minimumChecks > 0 ? "*" : ""}</p>
        ${this.options.map(
      (option) => ke`
            <jb-checkbox
              value="${option.value}"
              label="${option.label}"
            ></jb-checkbox>
          `
    )}
        ${this.showError ? ke`<p class="error">${this.requiredText}</p>` : D}
      </div>
    `;
  }
};
JbMultiCheckbox.styles = r(jb_multi_checkbox_default);
__decorateClass([
  n()
], JbMultiCheckbox.prototype, "label", 2);
__decorateClass([
  n({ type: Number })
], JbMultiCheckbox.prototype, "minimumChecks", 2);
__decorateClass([
  n()
], JbMultiCheckbox.prototype, "name", 2);
__decorateClass([
  n({ type: Array })
], JbMultiCheckbox.prototype, "options", 2);
__decorateClass([
  n()
], JbMultiCheckbox.prototype, "requiredText", 2);
__decorateClass([
  r2()
], JbMultiCheckbox.prototype, "showError", 2);
__decorateClass([
  r3("jb-checkbox")
], JbMultiCheckbox.prototype, "_checkboxes", 2);
JbMultiCheckbox = __decorateClass([
  customElement("jb-multi-checkbox")
], JbMultiCheckbox);

export {
  JbMultiCheckbox
};
