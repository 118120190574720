import {
  FormElement
} from "./chunk.UTVDNTSP.js";
import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  n,
  r as r2
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-text-area/jb-text-area.scss?inline
var jb_text_area_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
div p, div .counter, div label, div textarea::placeholder, div textarea {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

div textarea::placeholder, div textarea {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}

div .counter, div label {
  font-size: var(--theme-typo-p-mini-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-desktop-lineheight, 1.25rem);
}

div p {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, var(--theme-opacity-70, 0.7)));
  font-size: 0.813rem;
  line-height: 1.25rem;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
  width: 100%;
}

div {
  position: relative;
}
div label {
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
  display: block;
  margin-bottom: var(--spacing-base);
}
div textarea {
  background: transparent;
  border: 1px solid rgba(20, 30, 85, var(--theme-opacity-20, 0.2));
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
  height: 250px;
  overflow: auto;
  padding: var(--spacing-3xs);
  width: 100%;
}
div textarea::placeholder {
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
  opacity: 1;
  transition: opacity 0.3s linear 0s;
}
div textarea:focus {
  border-color: #141e55;
  outline: none;
}
div textarea:focus::placeholder {
  opacity: 0;
}
div textarea label {
  color: rgba(20, 30, 85, var(--theme-opacity-70, 0.7));
  opacity: 1;
  transition: opacity 0.3s ease-in 0s;
}
div.error textarea::placeholder {
  color: #971b2f;
}
div.error label {
  color: #971b2f;
}
div p {
  color: #971b2f;
  display: block;
  margin-top: 10px;
  position: relative;
}
div .counter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-start: var(--spacing-base);
  width: 100%;
}
div .counter > span:first-of-type {
  opacity: 0.7;
}`;

// src/components/jb-text-area/jb-text-area.ts
var JbTextArea = class extends Initiable(FormElement(h)) {
  constructor() {
    super(...arguments);
    this._currentLength = 0;
  }
  _handleInputChange(e) {
    this.value = e.target.value;
  }
  render() {
    return ke`
      <div
        class=${Rt({
      optional: this.requiredText === "" || !this.requiredText,
      error: this.showError || !!this.errorText,
      disabled: !!this.disabled
    })}
      >
        <label for=${this.name}>${this.label}${this.requiredText ? "*" : ""} </label>
        <textarea
          rows="9"
          cols="104"
          placeholder="${this.placeholder}"
          style="resize:vertical"
          ?disabled=${this.disabled}
          .maxLength=${this.maxLength ?? D}
          .name=${this.name}
          .id=${this.name}
          ?required=${!!this.requiredText}
          .value=${typeof this.value === "string" ? this.value : ""}
          @change=${this._handleInputChange}
          @input=${(evt) => {
      if (!this.maxLength) return;
      this._currentLength = evt.currentTarget.value.length;
    }}
        ></textarea>
        ${this.maxLength ? ke`<div class="counter">
              <span>${this.maxLengthText ?? D}</span><span>${this._currentLength} / ${this.maxLength}</span>
            </div>` : D}
        ${this.showError || this.errorText ? ke`<p>
              ${this.showError ? ke`<span>${this.requiredText}</span>` : D}
              ${this.errorText ? ke`<span>${this.errorText}</span>` : D}
            </p>` : D}
      </div>
    `;
  }
};
JbTextArea.styles = r(jb_text_area_default);
__decorateClass([
  n({ type: Number })
], JbTextArea.prototype, "maxLength", 2);
__decorateClass([
  n()
], JbTextArea.prototype, "maxLengthText", 2);
__decorateClass([
  n()
], JbTextArea.prototype, "placeholder", 2);
__decorateClass([
  r2()
], JbTextArea.prototype, "_currentLength", 2);
JbTextArea = __decorateClass([
  customElement("jb-text-area")
], JbTextArea);

export {
  JbTextArea
};
