import {
  FormElement
} from "./chunk.UTVDNTSP.js";
import {
  ae
} from "./chunk.Q25FCJRM.js";
import {
  Rt
} from "./chunk.LTAMHVKK.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  e,
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-checkbox/jb-checkbox.scss?inline
var jb_checkbox_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
div label, div {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

div {
  font-size: var(--theme-typo-p-mini-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-desktop-lineheight, 1.25rem);
}

div label {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, var(--theme-opacity-70, 0.7)));
  font-size: 0.813rem;
  line-height: 1.25rem;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
  width: 100%;
  --_checkbox-size: 28px;
}

div {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3xs);
}
div.error span::after {
  border-color: #971b2f;
}
div p {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--theme-opacity-70, 0.7));
}
div p.error-msg {
  color: #971b2f;
}
div label {
  display: inline-block;
  min-height: var(--_checkbox-size);
  padding-block-start: 5px;
  padding-inline-start: calc(var(--_checkbox-size) + var(--spacing-sm));
  position: relative;
  user-select: none;
}
div label:hover {
  cursor: pointer;
}
div label span::after {
  border: 1px solid rgb(var(--rgb-primary, 20, 30, 85));
  content: "";
  height: var(--_checkbox-size);
  left: 0;
  position: absolute;
  top: 0;
  width: var(--_checkbox-size);
}
div label span::before {
  border: 1px solid rgba(var(--rgb-primary, 20, 30, 85), var(--border-opacity, var(--theme-opacity-20, 0.2)));
  content: "";
  height: 10px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 10px;
}
@media (max-width: 599px) {
  div label span::before {
    left: 9px;
  }
}
div label span a {
  box-shadow: inset 0 -1px 0 0 RGBA(var(--rgb-primary, 20, 30, 85), var(--theme-opacity-30, 0.3));
  padding-bottom: 0.15em;
  text-decoration: none;
  transition: box-shadow 0.2s;
  color: rgb(var(--rgb-primary, 20, 30, 85));
}
div label span a:hover {
  box-shadow: inset 0 -1px 0 0 RGB(var(--rgb-primary, 20, 30, 85));
}
div input {
  display: none;
}
div input:checked + span::before {
  background-color: rgb(var(--rgb-primary, 20, 30, 85));
}
div input:disabled + span {
  opacity: 0.4;
}

label.multiple-lines {
  padding-block-start: 0;
}

div.has-before-checkbox-text.has-after-checkbox-text label > span {
  color: rgba(var(--rgb-primary, 20, 30, 85));
}

div.white.error label > span::after {
  border-color: #ff5570;
}
div.white.error p.error-msg {
  color: #ff5570;
}`;

// src/components/jb-checkbox/jb-checkbox.ts
var JbCheckbox = class extends Initiable(FormElement(h)) {
  constructor() {
    super(...arguments);
    this.checked = false;
  }
  async updated(_changedProperties) {
    if (this.frontendRenderingIdentifier) {
      let storedData = localStorage.getItem("savedFormData");
      if (storedData === null) {
        return;
      }
      storedData = JSON.parse(storedData);
      if (this.frontendRenderingIdentifier && this.frontendRenderingIdentifier in storedData) {
        this.checkbox.checked = storedData[this.frontendRenderingIdentifier] === "true";
      }
    }
    if (_changedProperties.has("label")) {
      await this.updateComplete;
      this._adjustLabelPaddingOnLineBreak();
    }
  }
  appendFormData(formDataEvent) {
    formDataEvent.formData.append(this.name, this.checkbox.checked ? "1" : "");
  }
  async firstUpdated() {
    const resizeObserver = new ResizeObserver(() => {
      this._adjustLabelPaddingOnLineBreak();
    });
    await this.updateComplete;
    const targetElement = this.renderRoot.querySelector("label");
    resizeObserver.observe(targetElement, {});
  }
  isChecked() {
    return !!this.checkbox.checked;
  }
  validate() {
    const valid = this.requiredText ? this.checkbox.checked : true;
    this.showError = !valid;
    return valid;
  }
  _adjustLabelPaddingOnLineBreak() {
    try {
      const singleLineHeight = 20;
      if (this.labelElement && this.labelContent && this.labelContent.offsetHeight > singleLineHeight) {
        this.labelElement.classList.add("multiple-lines");
      } else {
        this.labelElement.classList.remove("multiple-lines");
      }
    } catch (e2) {
    }
  }
  _handleInputChange(e2) {
    this.value = e2.target.value;
    if (this.frontendRenderingIdentifier) {
      let storedData = localStorage.getItem("savedFormData");
      if (storedData !== null) {
        storedData = JSON.parse(storedData);
      } else {
        storedData = {};
      }
      storedData[this.frontendRenderingIdentifier] = this.checkbox.checked.toString();
      localStorage.setItem("savedFormData", JSON.stringify(storedData));
    }
  }
  render() {
    return ke`
      <div
        class=${Rt({
      error: this.showError || !!this.errorText,
      "has-after-checkbox-text": this.afterCheckboxText || !!this.afterCheckboxText,
      "has-before-checkbox-text": this.beforeCheckboxText || !!this.beforeCheckboxText,
      white: !!this.isWhite
    })}
      >
        ${this.beforeCheckboxText ? ae(`
                <p class="before-checkbox-text">
                  ${this.beforeCheckboxText}
                </p> `) : D}
        <label>
          <input
            @change=${this._handleInputChange}
            type="checkbox"
            name=${this.name}
            .value=${typeof this.value === "string" ? this.value : ""}
            ?required=${!!this.requiredText}
            ?disabled=${this.disabled}
            ?checked=${this.checked}
          />
          <span>${ae(this.label)}${this.requiredText ? " *" : D}</span>
        </label>
        ${this.afterCheckboxText ? ae(`
              <p class="after-checkbox-text">
                ${this.afterCheckboxText}
              </p>
          `) : D}
        ${this.errorText ? ke`
              <p class="error-msg">
                <span>${this.errorText}</span>
              </p>
            ` : D}
        ${this.showError ? ke`
              <p class="error-msg">
                <span>${this.requiredText}</span>
              </p>
            ` : D}
      </div>
    `;
  }
};
JbCheckbox.styles = r(jb_checkbox_default);
__decorateClass([
  n()
], JbCheckbox.prototype, "afterCheckboxText", 2);
__decorateClass([
  n()
], JbCheckbox.prototype, "beforeCheckboxText", 2);
__decorateClass([
  e("input")
], JbCheckbox.prototype, "checkbox", 2);
__decorateClass([
  n({ type: Boolean })
], JbCheckbox.prototype, "checked", 2);
__decorateClass([
  n({ type: Boolean })
], JbCheckbox.prototype, "isWhite", 2);
__decorateClass([
  n()
], JbCheckbox.prototype, "label", 2);
__decorateClass([
  e("label span")
], JbCheckbox.prototype, "labelContent", 2);
__decorateClass([
  e("label")
], JbCheckbox.prototype, "labelElement", 2);
JbCheckbox = __decorateClass([
  customElement("jb-checkbox")
], JbCheckbox);

export {
  JbCheckbox
};
